.header {
  padding-left: 2rem;
  padding-right: 3rem;
  border-bottom: 3px solid black;
  background: #8fca5c;
}

.header-link {
  text-decoration: none;
  color: black;
}

.header-link:hover {
  text-shadow: 1px 1px gray;
}
