.about-container {
  max-width: 750px;
}

.paragraph {
  margin: 15px;
}

small.address {
  font-size: 8px;
  padding-left: 3px;
}
