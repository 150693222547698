html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  background: whitesmoke;
}

.height-total {
  height: 100%;
}

.width-total {
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-2 {
  flex: 2;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.no-padding {
  padding: 0;
}

.padding-3 {
  padding: 3px;
}

.padding-15 {
  padding: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-3 {
  margin: 3px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.no-margin {
  margin: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

button,
input {
  border: 1px solid black;
}

.bordered {
  border: 1px solid black;
}

.pointer {
  cursor: pointer;
}
